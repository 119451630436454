import React, { useContext, memo, useCallback, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import { css } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Tooltip from '@atlaskit/tooltip/Tooltip';

import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { useEditSource } from '@confluence/load-edit-page/entry-points/useEditSource';
import {
	CREATE_PAGE_SHORTCUT,
	CREATE_NAV_BUTTON_CLICK,
} from '@confluence/load-edit-page/entry-points/constants';
import { CREATE_CONTENT } from '@confluence/named-routes';
import {
	ShortcutVisualizer,
	BLOG_SHORTCUT,
	GLOBAL_CREATE_SHORTCUT,
	GLOBAL_CREATE_SHORTCUT_IN_EDIT_MAC,
	GLOBAL_CREATE_SHORTCUT_IN_EDIT_WIN,
	GeneralShortcutListener,
	useKeyboardShortcutsState,
	isMac,
	UNIVERSAL_CREATE_LIVE_DOC_SHORTCUT,
} from '@confluence/shortcuts';
import {
	isCreateBlankFabricPageEnabled,
	buildCreationLink,
	GlobalCreateContentPopover,
	GlobalCreateContentPopoverSpotlightLoader,
	UniversalCreate,
	ConfluencePageSubType,
} from '@confluence/create-blank-fabric-page';
import { useSpaceEnabledContentTypes } from '@confluence/create-blank-fabric-page/entry-points/useSpaceEnabledContentTypes';
import { WhiteboardGAModalManager } from '@confluence/whiteboards-onboarding';
import { LoomMigrationModalManager } from '@confluence/loom-onboarding';
import { DatabaseGAOnboardingModalManager } from '@confluence/databases-onboarding';
import { useIsProductAdmin } from '@confluence/current-user';
import { useSkippableCoordination } from '@confluence/skippable-coordination-client';
import { START_TOUCH } from '@confluence/navdex';
import { EDITOR_ONBOARDING_STATE_KEYS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import {
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { preloadEditorOnboardingState } from '@confluence/onboarding-helpers/entry-points/preloadEditorOnboardingState';
import { usePageSpaceKey, usePageContentId } from '@confluence/page-context';
import { CreateContentButtonPreloader } from '@confluence/edit-button/entry-points/preloaders';
import { useIsWhiteboardFeatureEnabled } from '@confluence/whiteboard-utils';
import {
	useIsCreationDropdownEnabled,
	isContentTypeEnabledInCurrentEnv,
} from '@confluence/content-types-utils';
import { CreateButtonNudgeWrappable } from '@confluence/onboarding-hover-nudge/entry-points/BorderNudge';
import { HOVER_TARGET } from '@confluence/onboarding-hover-nudge/entry-points/constants/HoverTarget';
import { useOnboardingNewHomeTakeoverEnabled } from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingNewHomeTakeover';
import { useHardStorageEnforcement } from '@confluence/storage-enforcement/entry-points/HardEnforcement/useHardStorageEnforcement';
import { sendPendoTrackEvent } from '@confluence/pendo';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { useSSRPlaceholderReplaceIdProp, LoadablePaint } from '@confluence/loadable';
import { fg } from '@confluence/feature-gating';
import { expValEquals } from '@confluence/feature-experiments';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';

import {
	EngagementInlineDialogLoader as EngagementInlineDialogLoaderNav4,
	EngagementInlineDialogLoaderNav3,
} from './EngagementInlineDialogLoader';
import { CreateButton as CreateButtonNav3 } from './Nav3/CreateButton';

const containerStyles = css({
	display: 'flex',
	alignItems: 'center',
});

const CreateButton = LoadablePaint<any>({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-TopNav" */ '@atlassian/navigation-system/top-nav'))
			.CreateButton,
});

export const CreateComponent = memo(() => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const isNav4Enabled = useIsNav4Enabled();
	const { setQueryParams, match, push, location } = useContext(RoutesContext);
	const [spaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isWhiteboardGAOnboardingEnabled } = useIsWhiteboardFeatureEnabled();
	const isCreationDropdownEnabled = useIsCreationDropdownEnabled();
	const { isProductAdmin } = useIsProductAdmin();
	const { enforceStorageLimit, shouldBlockCreate } = useHardStorageEnforcement({
		source: 'create-component',
	});
	const [areKeyboardShortcutsEnabled] = useKeyboardShortcutsState();
	const isDatabaseEnabled = isContentTypeEnabledInCurrentEnv('database');
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();

	const isCreateContentPage = Boolean(CREATE_CONTENT.match(location?.pathname || ''));

	const isOnEditRoute = useIsEditorPage();
	const { isTwoPageTypesExperience } = useIsLivePagesFeatureEnabled();
	const { isCreateLiveDocEnabled } = useSpaceEnabledContentTypes(spaceKey);

	// Whiteboard GA

	const [isWhiteboardGASpotlightEnabled, setIsWhiteboardGASpotlightEnabled] = useState(false);

	// GA onboarding is enabled + NOT on editor route + NOT on create-content route
	// Exclude the editor route to avoid disrupting a user's workflow when they're actively creating
	// Exclude the create-content route because it can quickly redirect and we don't want modal screen events firing twice
	const showWhiteboardGAOnboardingModal =
		isWhiteboardGAOnboardingEnabled() && !isOnEditRoute && !isCreateContentPage;

	const [hasStartedWhiteboardGAOnboardingCoordination, stopWhiteboardGAOnboardingCoordination] =
		useSkippableCoordination(
			'cc-whiteboards-ga-admin-onboarding-modal',
			!showWhiteboardGAOnboardingModal,
		);

	const onCloseWhiteboardGAOnboardingModal = useCallback(() => {
		void stopWhiteboardGAOnboardingCoordination();
		setIsWhiteboardGASpotlightEnabled(true);
	}, [setIsWhiteboardGASpotlightEnabled, stopWhiteboardGAOnboardingCoordination]);

	const onCloseWhiteboardGASpotlight = useCallback(() => {
		setIsWhiteboardGASpotlightEnabled(false);
	}, [setIsWhiteboardGASpotlightEnabled]);

	const [hasStartedLoomPremigrationModalCoordination, stopLoomPremigrationModalCoordination] =
		useSkippableCoordination(
			'cc-content-types-loom-premigration-modal',
			!fg('confluence_loom_premigration_modal'),
		);
	const onCloseLoomPremigrationModal = useCallback(() => {
		void stopLoomPremigrationModalCoordination();
	}, [stopLoomPremigrationModalCoordination]);

	const [hasStartedLoomMigrationModalCoordination, stopLoomMigrationModalCoordination] =
		useSkippableCoordination(
			'cc-content-types-loom-migration-modal',
			!fg('confluence_loom_migration_modal'),
		);
	const onCloseLoomMigrationModal = useCallback(() => {
		void stopLoomMigrationModalCoordination();
	}, [stopLoomMigrationModalCoordination]);

	const showDatabaseGAOnboardingModal =
		isDatabaseEnabled && isProductAdmin && !isOnEditRoute && !isCreateContentPage;

	const [hasStartedDatabaseGAOnboardingCoordination, stopDatabaseGAOnboardingCoordination] =
		useSkippableCoordination(
			'cc-databases-ga-admin-onboarding-modal',
			!showDatabaseGAOnboardingModal,
		);

	const onCloseDatabaseGAOnboardingModal = useCallback(() => {
		void stopDatabaseGAOnboardingCoordination();
	}, [stopDatabaseGAOnboardingCoordination]);

	const { isEnabled: isOnboardingNewHomeTakeoverEnabled } = useOnboardingNewHomeTakeoverEnabled();

	const [, { setEditSource }] = useEditSource();
	const { data } = useGetOnboardingState(
		Object.values({
			...EDITOR_ONBOARDING_STATE_KEYS,
		}),
		!fg('cc_onboarding_experience'),
	);

	const { editorOnboardingEligiblity } = deserializeState(data);

	const fireAnalytics = (additionalAttributes = {}) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'globalNavigation',
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'create',
				attributes: {
					selectedItemPageContext: match ? match.name : undefined,
					navigationLayer: 'global',
					navVersion: isNav4Enabled ? '4' : '3',
					navdexPointType: START_TOUCH,
					...additionalAttributes,
				},
			},
		}).fire();
	};

	const openCreateDialog = () => {
		fireAnalytics();
		setQueryParams({ createDialog: true });
	};

	const shouldCreateBlankFabricPage = (): boolean => {
		if (data && editorOnboardingEligiblity) {
			void preloadEditorOnboardingState();
		}

		return true;
	};

	const createPageHref = buildCreationLink({
		contentType: 'page',
		source: 'createBlankFabricPage',
		withFallback: true,
		spaceKey,
		parentPageId: contentId,
	});

	const handleCreatePageShortcut = () => {
		setEditSource(CREATE_PAGE_SHORTCUT);
		fireAnalytics({ keyboardShortcut: true });
		const shouldCreate = shouldCreateBlankFabricPage();

		// Send keyboard shortcut event for new page creation to Pendo, if running
		sendPendoTrackEvent(CREATE_PAGE_SHORTCUT, { keyboardShortcut: 'true' });

		if (shouldCreate) {
			push(createPageHref);
		}
	};
	const handleCreatePageButtonClick = (e: React.MouseEvent<HTMLElement>) => {
		fireAnalytics();
		const shouldCreate = shouldCreateBlankFabricPage();

		if (shouldCreate) {
			if (isNav4Enabled) {
				if (e.metaKey || e.ctrlKey) {
					window.open(createPageHref, '_blank', 'noreferrer');
				} else {
					push(createPageHref);
				}
			}
		} else {
			// creation is handled by href for button click
			e.preventDefault();
		}
	};

	const createBlog = () => {
		const createBlogLink = buildCreationLink({
			contentType: 'blog',
			source: 'globalCreateBlogShortcut',
			spaceKey,
		});
		push(createBlogLink);
	};

	const createLiveDoc = () => {
		const createLiveDocLink = buildCreationLink({
			contentType: 'page',
			subType: ConfluencePageSubType.LIVE,
			parentPageId: contentId,
			withFallback: true,
			source: 'globalCreateLiveDocShortcut',
			spaceKey,
		});
		push(createLiveDocLink);
	};

	const getButtonHref = () => {
		if (isCreateBlankFabricPageEnabled() && !shouldBlockCreate) {
			return createPageHref;
		}
		return undefined;
	};

	const [createPageClickListener, createPageShortcutListener] = isCreateBlankFabricPageEnabled()
		? [handleCreatePageButtonClick, handleCreatePageShortcut]
		: [openCreateDialog, openCreateDialog];

	/**
   * Please also update skeleton in appNavigationLazyItems
   *
   * <NavItemWrapper>
      <CreateButton />
    </NavItemWrapper>
   */

	const EngagementInlineDialogLoader = isNav4Enabled
		? EngagementInlineDialogLoaderNav4
		: EngagementInlineDialogLoaderNav3;

	const globalCreateContentPopover = useCallback(() => {
		if (expValEquals('navx_universal_create_experiment', 'cohort', 'variation')) {
			return <UniversalCreate />;
		}

		if (hasStartedWhiteboardGAOnboardingCoordination || isWhiteboardGASpotlightEnabled) {
			return (
				<CreateButtonNudgeWrappable
					target={HOVER_TARGET.CREATE}
					shouldWrap={isOnboardingNewHomeTakeoverEnabled}
				>
					<GlobalCreateContentPopoverSpotlightLoader
						isGA
						spotlightEnabled={isWhiteboardGASpotlightEnabled}
						onSpotlightClose={onCloseWhiteboardGASpotlight}
					/>
				</CreateButtonNudgeWrappable>
			);
		}

		return (
			<CreateButtonNudgeWrappable
				target={HOVER_TARGET.CREATE}
				shouldWrap={isOnboardingNewHomeTakeoverEnabled}
			>
				<GlobalCreateContentPopover />
			</CreateButtonNudgeWrappable>
		);
	}, [
		isOnboardingNewHomeTakeoverEnabled,
		hasStartedWhiteboardGAOnboardingCoordination,
		isWhiteboardGASpotlightEnabled,
		onCloseWhiteboardGASpotlight,
	]);

	const createPageShortcutInEdit = isMac()
		? GLOBAL_CREATE_SHORTCUT_IN_EDIT_MAC
		: GLOBAL_CREATE_SHORTCUT_IN_EDIT_WIN;

	return (
		<div
			data-testid="app-navigation-create"
			data-pendo-id="con-*/wiki/home-create"
			data-vc="create-component"
			{...ssrPlaceholderIdProp}
			css={containerStyles}
		>
			<EngagementInlineDialogLoader
				engagementId="createComponentButton"
				dataVC="app-navigation-create-engagement-inline-dialog"
			>
				{isCreationDropdownEnabled ? (
					globalCreateContentPopover()
				) : (
					<Tooltip
						content={
							areKeyboardShortcutsEnabled && (
								<ShortcutVisualizer
									shortcut={
										isLiveEditMode ||
										(isOnEditRoute && fg('confluence_frontend_edit_page_keyboard_shortcut'))
											? createPageShortcutInEdit
											: GLOBAL_CREATE_SHORTCUT
									}
								/>
							)
						}
					>
						<CreateContentButtonPreloader editSource={CREATE_NAV_BUTTON_CLICK}>
							<CreateButtonNudgeWrappable
								target={HOVER_TARGET.CREATE}
								shouldWrap={isOnboardingNewHomeTakeoverEnabled}
							>
								{isNav4Enabled ? (
									<CreateButton onClick={enforceStorageLimit(createPageClickListener)}>
										<FormattedMessage {...i18n.create} />
									</CreateButton>
								) : (
									<CreateButtonNav3
										onClick={enforceStorageLimit(createPageClickListener)}
										href={getButtonHref()}
									/>
								)}
							</CreateButtonNudgeWrappable>
						</CreateContentButtonPreloader>
					</Tooltip>
				)}
			</EngagementInlineDialogLoader>
			<GeneralShortcutListener
				accelerator={
					isLiveEditMode || isOnEditRoute ? createPageShortcutInEdit : GLOBAL_CREATE_SHORTCUT
				}
				listener={enforceStorageLimit(createPageShortcutListener)}
				isAppNavigationShortcut
			/>
			{!isLiveEditMode && !isOnEditRoute && (
				<GeneralShortcutListener
					accelerator={BLOG_SHORTCUT}
					listener={enforceStorageLimit(createBlog)}
					isAppNavigationShortcut
				/>
			)}
			{(isCreateLiveDocEnabled || fg('confluence_live_docs_force_create_option')) &&
				isTwoPageTypesExperience && (
					<GeneralShortcutListener
						accelerator={UNIVERSAL_CREATE_LIVE_DOC_SHORTCUT}
						listener={enforceStorageLimit(createLiveDoc)}
						isAppNavigationShortcut
					/>
				)}
			{showWhiteboardGAOnboardingModal && hasStartedWhiteboardGAOnboardingCoordination && (
				<WhiteboardGAModalManager
					onClose={onCloseWhiteboardGAOnboardingModal}
					parentId={contentId}
					spaceKey={spaceKey}
				/>
			)}
			{
				// eslint-disable-next-line confluence-feature-gating/no-preconditioning
				hasStartedLoomPremigrationModalCoordination &&
					fg('confluence_loom_beta_enabled') &&
					fg('confluence_loom_premigration_modal') && (
						<LoomMigrationModalManager isPremigration onClose={onCloseLoomPremigrationModal} />
					)
			}
			{hasStartedLoomMigrationModalCoordination &&
				// Migration
				// This feature gate has the rule: `loombetaprovision` none of `migrated-site` => 100% fail
				// since there not a different feature gate to let us know if the user is migrated
				fg('confluence_loom_migration_modal') && (
					<LoomMigrationModalManager onClose={onCloseLoomMigrationModal} />
				)}
			{showDatabaseGAOnboardingModal && hasStartedDatabaseGAOnboardingCoordination && (
				<DatabaseGAOnboardingModalManager
					onClose={onCloseDatabaseGAOnboardingModal}
					parentId={contentId}
					spaceKey={spaceKey}
				/>
			)}
		</div>
	);
});

const i18n = defineMessages({
	create: {
		id: 'app-navigation.top-navigation.create',
		defaultMessage: 'Create',
	},
});
