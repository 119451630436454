import React, { Fragment, useCallback, useContext, useState, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import UniversalCreateButton from '@atlassian/universal-create';

import { withFlags } from '@confluence/flags';
import { START } from '@confluence/navdex';
import { AppNavigationContext } from '@confluence/app-navigation-context';
import { Attribution, ErrorDisplay, TransparentErrorBoundary } from '@confluence/error-boundary';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { useLiveDocChangeboarding } from '@confluence/onboarding-live-doc-changeboarding/entry-points/useLiveDocChangeboarding';

import { useMenuItems } from './useMenuItems';
import { token } from '@atlaskit/tokens';

const messages = defineMessages({
	create: {
		id: 'create-blank-fabric-page.universal-create.create.text',
		defaultMessage: 'Create',
		description: 'The Create text that appears in the top nav',
	},
});

export const getCreateButtonTheme = (createButtonTheme) => (current, props) => {
	const { buttonStyles, spinnerStyles } = current(props);
	return {
		buttonStyles: {
			...buttonStyles,
			...createButtonTheme.default,
			margin: 0,
			fontWeight: token('font.weight.medium'),
			':hover': createButtonTheme.hover,
			':focus': createButtonTheme.focus,
			// :active doesn't work in FF, because we do a
			// e.preventDefault() on mouse down in Button.
			// '&&' is required to add more CSS specificity
			// && it not a valid CSSObject property
			// @ts-ignore
			'&&': {
				...(props.state === 'active' && createButtonTheme.active),
			},
		},
		spinnerStyles,
	};
};

const Create = ({ flags }) => {
	const [isOpen, setIsOpen] = useState(false);
	const items = useMenuItems(flags, () => setIsOpen(false));
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { match } = useContext(RoutesContext);
	const { theme } = useContext(AppNavigationContext);
	const isNav4Enabled = useIsNav4Enabled();
	const isOnEditRoute = useIsEditorPage();
	const { formatMessage } = useIntl();
	const [shouldShowPulse, setShouldShowPulse] = useState(false);
	const {
		introSpotlight: { isVisible: shouldShowIntroSpotlight },
		error,
	} = useLiveDocChangeboarding();

	const buttonTheme = theme?.mode?.create ? getCreateButtonTheme(theme.mode.create) : undefined;

	useEffect(() => {
		if (shouldShowIntroSpotlight) {
			setShouldShowPulse(true);
		}
	}, [shouldShowIntroSpotlight]);

	const onCreateClick = useCallback(() => {
		setIsOpen(true);
		setShouldShowPulse(false);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'globalCreateContentPopover',
				source: 'globalNavigation',
				attributes: {
					selectedItemPageContext: match?.name,
					navigationLayer: 'global',
					navigationContainer: 'top',
					navVersion: isNav4Enabled ? '4' : '3',
					navdexPointType: START,
					isOnEditRoute,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, isNav4Enabled, isOnEditRoute, match?.name]);
	return (
		<Fragment>
			<UniversalCreateButton
				items={items}
				onClick={onCreateClick}
				triggerButtonSpotlightTarget="top-nav-create"
				enableTriggerPulse={shouldShowPulse}
				isNav4Enabled={isNav4Enabled}
				isOpen={isOpen}
				product="confluence"
				buttonTheme={buttonTheme}
			>
				{formatMessage(messages.create)}
			</UniversalCreateButton>
			{error && <ErrorDisplay error={error} />}
		</Fragment>
	);
};

const CreateWithFlags = withFlags(Create);

export function UniversalCreate() {
	return (
		<TransparentErrorBoundary attribution={Attribution.NAVX}>
			<CreateWithFlags />
		</TransparentErrorBoundary>
	);
}
