import type { MutableRefObject } from 'react';
import { createStore, createHook } from 'react-sweet-state';

type CreateSpaceButtonLocationType =
	| 'topCreateNav'
	| 'personalizedTemplatesV2ExperimentSetupTeamSpaceCard'
	| undefined;

type State = {
	showCreateSpaceDrawer: boolean;
	shouldRenderDrawer: boolean;
	createSpaceButtonRef: MutableRefObject<HTMLElement | null>;
	createSpaceButtonLocation: CreateSpaceButtonLocationType;
};

const initialState: State = {
	showCreateSpaceDrawer: false,
	shouldRenderDrawer: false,
	createSpaceButtonRef: {
		current: null,
	} as MutableRefObject<HTMLElement | null>,
	createSpaceButtonLocation: undefined,
};
export const actions = {
	openDrawer:
		(
			value: MutableRefObject<HTMLElement | null>,
			createSpaceButtonLocation: CreateSpaceButtonLocationType,
		) =>
		({ setState }) => {
			setState({
				showCreateSpaceDrawer: true,
				shouldRenderDrawer: true,
				createSpaceButtonRef: value,
				createSpaceButtonLocation,
			});
		},
	closeDrawer:
		() =>
		({ setState }) => {
			setState({ showCreateSpaceDrawer: false, createSpaceButtonLocation: undefined });
		},
};

export const Store = createStore({
	initialState,
	actions,
	name: 'createSpaceStore',
});

export const useCreateSpaceStore = createHook(Store);

const getShouldRenderDrawer = (state: { shouldRenderDrawer: boolean }) => {
	return state.shouldRenderDrawer;
};

export const useShouldRenderDrawer = createHook(Store, {
	selector: getShouldRenderDrawer,
});

const getIsCreateSpaceDrawerShowing = (state: { showCreateSpaceDrawer: boolean }) => {
	return state.showCreateSpaceDrawer;
};

export const useIsCreateSpaceDrawerShowing = createHook(Store, {
	selector: getIsCreateSpaceDrawerShowing,
});
